body {
    background-image: url('https://images.unsplash.com/photo-1511447333015-45b65e60f6d5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1255&q=80');
    background-size: cover; 
  }
  
  .signin {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2em;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 10px;
    margin-top: 10%;
  }
  
  .signin_welcom {
    font-size: 2em;
    margin-bottom: 1em;
    text-align: center;
    color: #333;
  }
  
  .signin__btn {
    padding: 1em 2em;
    background-color: #4285f4;
    color: white;
    font-size: 1.2em;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 1em;
  }
  
  .signin__btn:hover {
    background-color: #357ae8;
  }

  .signin-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .signin-card {
    background-color: white;
    padding: 2em;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
  }
  
  .signin-header {
    text-align: center;
    margin-bottom: 2em;
  }
  
  .signin-header h1 {
    color: #333;
    font-size: 2.5em;
  }
  
  .signin-body {
    text-align: center;
    margin-bottom: 2em;
  }
  
  .signin-body p {
    color: #333;
    font-size: 1.2em;
    margin-bottom: 1em;
  }

  .message__markdown {
    color: white;
    font-size: 1.3rem;
    margin-top: .6rem; 
    line-height: 1.5;
  }
  
  .avatar{
    margin-top: 1vw;
  }

  /* Rubberduck - GPT-3 Chatbot */